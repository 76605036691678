export default defineNuxtPlugin(nuxtApp => {
  const { gtagId, gtagTenantIds } = useRuntimeConfig().public;

  function gtag() {
    window.dataLayer.push(arguments);
  }

  window.dataLayer = window.dataLayer || [];
  gtag("js", new Date());
  gtag("config", gtagId);

  // 添加异常处理逻辑
  try {
    if (
      gtagTenantIds.includes(import.meta.env.VITE_TENANT_ID) &&
      import.meta.env.VITE_NODE_ENV === "prod"
    ) {
      useHead({
        script: [
          {
            src: `https://www.googletagmanager.com/gtag/js?id=${gtagId}`,
            async: true,
            // 添加加载失败时的错误处理
            onload: function () {
              console.log("GTM script loaded successfully");
            },
            onerror: function () {
              console.log("Failed to load GTM script");
              // 可以记录错误，或者执行其他操作，比如切换到备用监控方案
            }
          }
        ]
      });
    }
  } catch (error) {
    console.log("Error loading GTM script:", error);
    // 可以记录错误，或者执行其他操作
  }
});
