export const useUserStore = defineStore("user", {
  state() {
    return {
      menuList: [], // 菜单
      userInfo: {}, // 0 审核中 1 审核通过 2 审核驳回
      customerInfo: {},
      accessToken: "",
      refreshToken: "",
      oneTimeLoginEmail: "",
      zipCodeInfo: {
        postCode: "",
        residential: false
      }
    };
  },
  actions: {
    setUserInfo(userInfo) {
      this.userInfo = {
        ...this.userInfo,
        ...userInfo
      };
    },
    setMenuList(menu) {
      this.menuList = menu;
    },
    setCustomerInfo(customerInfo) {
      this.customerInfo = {
        ...this.customerInfo,
        ...customerInfo
      };
    },
    setAccessToken(accessToken) {
      this.accessToken = accessToken;
    },
    setRefreshToken(refreshToken) {
      this.refreshToken = refreshToken;
    },
    setOneTimeLoginEmail(email) {
      this.oneTimeLoginEmail = email;
    },
    // 设置zipcode
    setZipCodeInfo(info) {
      this.zipCodeInfo = Object.assign(this.zipCodeInfo, info);
    },
    resetAll() {
      this.userInfo = {};
      this.accessToken = "";
      this.refreshToken = "";
      this.customerInfo = {};
    }
  },
  persist: true
});
