import wewoo from "@wewoo/error";
export default defineNuxtPlugin(nuxtApp => {
  if (import.meta.env.VITE_NODE_ENV !== "dev") {
    let userValue = localStorage.getItem("user");
    userValue = userValue ? JSON.parse(userValue) : {};
    const userId = userValue?.userInfo?.userId ?? "1716332637171220480";
    let dsn = window.location.origin;
    nuxtApp.vueApp.use(wewoo, {
      dsn: dsn + "/wewoo-log-center/monitor/reportErrorData",
      sysCode: "SHOPMALL",
      appCode: 10003,
      repeatCodeError: false,
      useImgUpload: false,
      userId,
      handleHttpStatus() {
        return true;
      }
    });
  }
});
