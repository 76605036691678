import request from "../request";

// 购物车订单结账付款
export function orderCheckOut(data) {
  return request({
    url: "/order/order/orderCheckOut",
    method: "post",
    body: data,
    headers: {
      encode: true,
      decode: true
    }
  });
}

// 订单详情
export function orderDetail(data) {
  return request({
    url: "/order/order/orderDetail",
    method: "post",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      decode: true
    }
  });
}

// 我的订单列表
export function myOrders(data) {
  return request({
    url: "/order/order/myOrders",
    method: "post",
    body: data,
    headers: {
      decode: true
    }
  });
}

// 计算订单预计到达时间日期（购物车下单页面使用）
export function calculateOrderDelivery(data) {
  return request({
    url: "/order/order/calculateOrderDelivery",
    method: "post",
    body: data
  });
}

// 订单打印
export function printOrderInvoice(data) {
  return request({
    url: `/order/order/printOrderInvoice`,
    method: "get",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 查询物流信息
export function logistics(data) {
  return request({
    url: "/order/order/logistics",
    method: "post",
    body: data
  });
}

// 客户在商城的订单详情中，点击确认样品 状态和流程节点更新
export function sampleApproved(data) {
  return request({
    url: "/order/order/sampleApproved",
    method: "post",
    body: data
  });
}

// 查询订单详情ConcatUS的联系邮箱
export function findBusinessEmail(data) {
  return request({
    url: "/order/order/findBusinessEmail",
    method: "post",
    body: data
  });
}

// 订单编辑产品效果图
export function editDesign(data) {
  return request({
    url: "/order/order/editDesign",
    method: "post",
    body: data,
    headers: {
      decode: true
    }
  });
}

// 根据商城订单号查询ID
export function getIdByCode(data) {
  return request({
    url: "/order/order/getIdByCode",
    method: "post",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 根据商城订单号查询物流信息
export function logisticsByCode(data) {
  return request({
    url: "/order/order/logisticsByCode",
    method: "post",
    body: data
  });
}

// 判断客户是否存在老发票
export function checkOrderInvoice() {
  return request({
    url: "/customer/oldCustomerInvoice/checkOrderInvoice",
    method: "post"
  });
}

// 获取历史订单
export function listOrderInvoice(data) {
  return request({
    url: "/customer/oldCustomerInvoice/listOrderInvoice",
    method: "post",
    body: data
  });
}
