import request from "../request";

// 分页材质
export function materialPage(data) {
  return request({
    url: "/product/api/v1/category-material/page",
    method: "post",
    body: data
  });
}

// 获取印刷方式列表
export function printingWay() {
  return request({
    url: "/product/api/v1/printing-way/list",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 产品信息详情
export function productDetails(id) {
  return request({
    url: `/product/api/v1/product/detail/${id}`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取类目列表
export function categorys() {
  return request({
    url: `/product/api/v1/category/list`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 产品搜索列表
export function productPage(data) {
  return request({
    url: `/product/api/v1/product/page`,
    method: "post",
    body: data
  });
}

// 图片格式转化
export function imageConvert(data) {
  return request({
    url: `/product/api/v1/product/imageConvert`,
    method: "post",
    responseType: "stream",
    body: data,
    headers: {
      "Content-Type": `multipart/form-data;`
    }
  });
}

// 计算产品价格
export function alculateProductPrice(data) {
  return request({
    url: `/product/api/v1/product/alculateProductPrice`,
    method: "post",
    body: data
  });
}

// 计算产品预计到达时间
export function alculateProductDelivery(data) {
  return request({
    url: `/product/api/v1/product/alculateProductDelivery`,
    method: "post",
    body: data
  });
}

// 添加购物车
export function cartAdd(data) {
  return request({
    url: `/cart/api/v1/cart/add`,
    method: "post",
    body: data
  });
}

// 获取产品id
export function getIdByCode(data) {
  return request({
    url: `/product/api/v1/product/getIdByCode`,
    method: "get",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 相关产品
export function productRelated(id) {
  return request({
    url: `/product/api/v1/product/related/${id}`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取销量好的产品列表
export function listBestSellers() {
  return request({
    url: "/product/api/v1/product/listBestSellers",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取销新品产品列表
export function listNewArrival() {
  return request({
    url: "/product/api/v1/product/listNewArrival",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

export function getProductDetailByCode(code) {
  return request({
    url: `/product/api/v1/product/detail/code/${code}`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

export function listRecommend() {
  return request({
    url: "/product/api/v1/product/listRecommend",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 获取产品不规则标记
export function getIrregularFlag(id) {
  return request({
    url: `/product/api/v1/product/getIrregularFlag/${id}`,
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}

// 产品信息详情
export function orderProductSnapshot(data) {
  return request({
    url: "/order/order/orderProductSnapshot",
    method: "post",
    query: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
}
