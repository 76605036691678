import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";

import {
  VBtn,
  VImg,
  VCard,
  VCardText,
  VCardActions,
  VIcon,
  VTextField,
  VTextarea,
  VSelect,
  VCheckbox,
  VSwitch,
  VDivider,
  VForm,
  VContainer,
  VRow,
  VCol,
  VSpacer,
  VSlideGroup,
  VSlideGroupItem,
  VPagination,
  VExpandTransition,
  VToolbar,
  VTooltip,
  VNavigationDrawer,
  VAppBarNavIcon,
  VToolbarTitle,
  VApp,
  VMain,
  VListItem,
  VLayout,
  VCarousel,
  VCarouselItem,
  VBtnGroup,
  VMenu,
  VList,
  VListItemTitle,
  VColorPicker,
  VDialog,
  VCardTitle,
  VRadio,
  VRadioGroup,
  VTable,
  VTimeline,
  VTimelineItem,
  VAvatar,
  VAlert,
  VOverlay,
  VSnackbar,
  VChip,
  VChipGroup,
  VProgressCircular,
  VLazy,
  VExpansionPanels,
  VExpansionPanel,
  VListSubheader,
  VExpansionPanelTitle,
  VTabs,
  VTab,
  VWindow,
  VWindowItem,
  VSkeletonLoader
} from "vuetify/components";

/**
 * 定义customLightTheme主题
 */

export default defineNuxtPlugin(nuxtApp => {
  if (nuxtApp.vueApp._vuetifyRegistered) {
    return; // 已经注册，退出插件
  }
  const config = useRuntimeConfig();
  const customLightTheme = {
    dark: false,
    colors: {
      primary: config.public.primaryColor, // 基础色
      error: "#d9001bbf", // 错误色
      info: "#2196F3", // 提示色
      success: "#4caf50", // 成功色
      warning: "#FB8C00", // 警告色
      black: "#000000", //黑色
      black2: "#555555", //黑色2
      black3: "#333333", // 黑色3
      black4: "#7F7F7F", //黑色4
      white: "#ffffff", //白色
      gray: "#AAAAAA", // 灰色
      "surface-variant": "#ffffff",
      "on-surface-variant": "#000000",
      selected: config.public.primaryColor,
      border: config.public.primaryColor
    }
  };

  /**
   * 定义select，checkbox，input公共配置
   */
  const baseFormFieldOption = {
    color: "primary",
    variant: "outlined",
    clearable: true,
    height: "40px",
    density: "compact"
    // baseColor: "#999999",
    // itemColor: "#000000",
  };

  /**
   * 定义按钮公共配置
   */
  const baseButtonOption = {
    elevation: 0
  };
  const vuetify = createVuetify({
    ssr: true,
    aliases: {
      VBtnDefault: VBtn
    },
    defaults: {
      VBtnDefault: {
        ...baseButtonOption,
        class: ["v-btn--primary"],
        variant: "outlined",
        color: "primary"
      },
      VBtn: {
        ...baseButtonOption
      },
      VTextField: {
        //文本框默认配置
        ...baseFormFieldOption
      },
      VTextarea: {
        variant: "outlined",
        rows: 3,
        rowHeight: "25",
        color: "primary"
      },

      VSelect: {
        //下拉框默认配置
        ...baseFormFieldOption
      },
      VCheckbox: {
        //复选框默认配置
        ...baseFormFieldOption
      },
      VTooltip: {
        style: "color:red"
      }
    },
    theme: {
      defaultTheme: "customLightTheme",
      themes: {
        customLightTheme
      }
    },
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi
      }
    },
    components: {
      VBtn,
      VImg,
      VCard,
      VCardText,
      VCardActions,
      VIcon,
      VTextField,
      VTextarea,
      VSelect,
      VCheckbox,
      VSwitch,
      VDivider,
      VForm,
      VContainer,
      VRow,
      VCol,
      VSpacer,
      VSlideGroup,
      VSlideGroupItem,
      VPagination,
      VExpandTransition,
      VToolbar,
      VTooltip,
      VNavigationDrawer,
      VAppBarNavIcon,
      VToolbarTitle,
      VApp,
      VMain,
      VListItem,
      VLayout,
      VCarousel,
      VCarouselItem,
      VBtnGroup,
      VMenu,
      VList,
      VListItemTitle,
      VColorPicker,
      VDialog,
      VCardTitle,
      VRadio,
      VRadioGroup,
      VTable,
      VTimeline,
      VTimelineItem,
      VAvatar,
      VAlert,
      VOverlay,
      VSnackbar,
      VChip,
      VChipGroup,
      VProgressCircular,
      VLazy,
      VExpansionPanels,
      VExpansionPanel,
      VListSubheader,
      VExpansionPanelTitle,
      VTabs,
      VTab,
      VWindow,
      VWindowItem,
      VSkeletonLoader
    }
  });

  nuxtApp.vueApp.use(vuetify);
  // 设置标志，表示 Vuetify 已经注册
  nuxtApp.vueApp._vuetifyRegistered = true;
});
