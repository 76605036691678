import { submitContractInfo } from "../services/api/home";

class HomeInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new HomeInteractor();

  //登录和获取登录信息
  async submitContractInfoApi(loginName, passWord) {
    //登录
    let res = await submitContractInfo({
      loginName,
      passWord
    });
    return res;
  }
}

export default HomeInteractor.getInstance();
