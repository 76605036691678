import {
  orderCheckOut,
  orderDetail,
  myOrders,
  calculateOrderDelivery,
  printOrderInvoice,
  logistics,
  sampleApproved,
  findBusinessEmail,
  editDesign,
  getIdByCode,
  logisticsByCode,
  checkOrderInvoice,
  listOrderInvoice
} from "../services/api/order";

class orderInteractor {
  static getInstance() {
    return this._instance;
  }
  static _instance = new orderInteractor();

  /**
   * 购物车订单结账付款
   * @param {billToAddress} 账单地址
   * @param {billToEmail} 账单邮箱
   * @param {cartIds} 购物车ID清单
   * @param {customerPo} 客户PO号
   * @param {note} 客户留言
   * @param {payAmount} 应支付金额
   * @param {paymentInfo} 支付账户信息
   * @param {sampleToAddress} 样品地址
   * @param {serviceCharge} 服务费
   * @param {shipToAddress} 收货地址
   * @param {totalAmount} 订单金额
   * @param {screenHeight} 屏幕高度
   * @param {screenWidth} 屏幕宽度
   */
  orderCheckOutApi(params) {
    return orderCheckOut(params);
  }

  /**
   * 订单详情
   * @param {orderId}
   */
  orderDetailApi(orderId) {
    return orderDetail({ orderId: orderId });
  }

  /**
   * 我的订单列表
   * @param {current} 当前页
   * @param {searchCount} 每页条数
   * @param {userId} 用户id
   * @param {status} 订单状态
   */
  myOrdersApi(params) {
    return myOrders(params);
  }

  /**
   * 计算订单预计到达时间日期（购物车下单页面使用）
   * @param {cartIds} 购物车id集合
   */
  calculateOrderDeliveryApi(cartIds) {
    return calculateOrderDelivery(cartIds);
  }

  /**
   * 订单打印
   * @param {id} 订单id
   */
  printOrderInvoiceApi(data) {
    return printOrderInvoice(data);
  }

  /**
   * 查询物流信息
   * @param {orderMallId} 商城订单ID
   * @param {sampleFlag} 查询类型（null:全部，0：正常产品物流 1：样品物流）
   */
  logisticsApi(orderMallId, sampleFlag) {
    return logistics({
      orderMallId: orderMallId,
      sampleFlag: sampleFlag
    });
  }

  /**
   * sampleApproved
   * @param {orderId} 商城订单ID
   *
   */
  sampleApprovedApi(orderId) {
    return sampleApproved({
      orderId
    });
  }

  /**
   * 查询订单详情ConcatUS的联系邮箱
   *
   * @param {orderId} 商城订单ID
   *
   */
  findBusinessEmailApi(orderId) {
    return findBusinessEmail({
      orderId
    });
  }

  /**
   * 查询订单详情ConcatUS的联系邮箱
   *
   * @param {orderId} 商城订单ID
   *
   */
  editDesignApi({
    orderId,
    orderProductId,
    orderProductPrintingId,
    printingLocations,
    printingSurfaces,
    productPic,
    productPicUrl
  }) {
    return editDesign({
      orderId,
      orderProductId,
      orderProductPrintingId,
      printingLocations,
      printingSurfaces,
      productPic,
      productPicUrl
    });
  }

  /**
   * 根据商城订单号查询id
   * @param {orderCode}
   */
  getIdByCodeApi(code) {
    return getIdByCode({ orderCode: code });
  }

  /**
   * 根据商城订单号查询物流信息
   * @param {orderCode}
   * @param {email}
   */
  logisticsByCodeApi(data) {
    const { orderCode, email } = data;
    return logisticsByCode({ orderCode, email });
  }

  // 判断客户是否存在老发票
  checkOrderInvoiceApi() {
    return checkOrderInvoice();
  }

  /**
   * 获取历史订单发票
   * @param {size}
   * @param {current}
   */
  listOrderInvoiceApi(data) {
    const { size, current } = data;
    return listOrderInvoice({ size, current });
  }
}

export default orderInteractor.getInstance();
